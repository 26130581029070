<template>
  <div>
    <div :class="{ loading: loading }" class="round-robin">
      <div class="w-full md:w-3/4 m-auto mt-12">
        <p class="p-light">
          One of the purposes of the ET Users Group is to verify standardization
          efforts with Round Robin Testing. Multiple government and industry
          laboratories have participated in the Round Robin testing with the
          In-Process sensitivity testing equipment. If you are interested in
          participating, please fill out the below form and we’ll get in contact
          with you.
        </p>
      </div>

      <div class="w-full md:w-3/4 m-auto mt-8">
        <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div class="flex flex-wrap">
            <div class="w-full md:w-1/2 md:pr-2">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="name"
                >
                  Name, first and last (required)
                </label>
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="name"
                  id="name"
                  type="text"
                  v-model="name"
                  placeholder=""
                  required
                />
              </div>
            </div>
            <div class="w-full md:w-1/2 md:pl-2">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="title"
                >
                  Title or Position
                </label>
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="title"
                  id="title"
                  type="text"
                  v-model="title"
                  placeholder=""
                />
              </div>
            </div>
            <div class="w-full md:w-1/2 md:pr-2">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="entity"
                >
                  Your Company or Entity (required)
                </label>
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="entity"
                  id="entity"
                  type="text"
                  v-model="entity"
                  placeholder=""
                  required
                />
              </div>
            </div>
            <div class="w-full md:w-1/2 md:pl-2">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="location"
                >
                  Your Location (required)
                </label>
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="location"
                  id="location"
                  type="text"
                  v-model="location"
                  placeholder=""
                  required
                />
              </div>
            </div>
            <div class="w-full md:w-1/2 md:pr-2">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="email"
                >
                  Your Email (required)
                </label>
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="email"
                  id="email"
                  type="email"
                  v-model="email"
                  placeholder=""
                  required
                />
              </div>
            </div>
            <div class="w-full md:w-1/2 md:pl-2">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="phone"
                >
                  Your Phone (required)
                </label>
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="phone"
                  id="phone"
                  type="tel"
                  v-model="phone"
                  placeholder=""
                  required
                />
              </div>
            </div>
          </div>

          <p class="p-light">
            The ET Users Group Standardization Efforts (Round Robin testing) are
            focused on the following pieces of equipment to date: MBOM Impact,
            ABL ESD, ABL Friction, BAM Friction, DSC, SBAT, and Koenen. Which
            pieces of equipment would you be using for the Round Robin?
          </p>

          <div class="flex flex-wrap">
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="mbom"
                >
                  MBOM Impact
                </label>
                <input name="mbom" id="mbom" type="checkbox" v-model="mbom" />
              </div>
            </div>
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="ablesd"
                >
                  ABL ESD
                </label>
                <input
                  name="ablesd"
                  id="ablesd"
                  type="checkbox"
                  v-model="ablesd"
                />
              </div>
            </div>
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="ablfriction"
                >
                  ABL Friction
                </label>
                <input
                  name="ablfriction"
                  id="ablfriction"
                  type="checkbox"
                  v-model="ablfriction"
                />
              </div>
            </div>
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="bamfriction"
                >
                  BAM Friction
                </label>
                <input
                  name="bamfriction"
                  id="bamfriction"
                  type="checkbox"
                  v-model="bamfriction"
                />
              </div>
            </div>
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="dsc"
                >
                  DSC
                </label>
                <input name="dsc" id="dsc" type="checkbox" v-model="dsc" />
              </div>
            </div>
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="sbat"
                >
                  SBAT
                </label>
                <input name="sbat" id="sbat" type="checkbox" v-model="sbat" />
              </div>
            </div>
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="koenen"
                >
                  Koenen
                </label>
                <input
                  name="koenen"
                  id="koenen"
                  type="checkbox"
                  v-model="koenen"
                />
              </div>
            </div>
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="detonator"
                >
                  Sand Crush Test
                </label>
                <input
                  name="detonator"
                  id="detonator"
                  type="checkbox"
                  v-model="sandcrushtest"
                />
              </div>
            </div>
          </div>

          <p class="p-light">
            Which detection method do you expect to use for reaction detection
            for the Standardization Verification/ Round Robin Testing?
          </p>

          <div class="flex flex-wrap">
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="hsv"
                >
                  High-speed Video
                </label>
                <input name="hsv" id="hsv" type="checkbox" v-model="hsv" />
              </div>
            </div>
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="godetect"
                >
                  GoDetectTM
                </label>
                <input
                  name="godetect"
                  id="godetect"
                  type="checkbox"
                  v-model="godetect"
                />
              </div>
            </div>
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="gasanalyzer"
                >
                  Gas Analyzer
                </label>
                <input
                  name="gasanalyzer"
                  id="gasanalyzer"
                  type="checkbox"
                  v-model="gasanalyzer"
                />
              </div>
            </div>
          </div>

          <div class="mb-4">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="vidmakemodel"
            >
              High-speed Video Make and Model
            </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="vidmakemodel"
              id="vidmakemodel"
              type="text"
              v-model="vidmakemodel"
              placeholder=""
            />
          </div>

          <div class="mb-4">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="gasmakemodel"
            >
              Gas Analyzer Make and Model
            </label>
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="gasmakemodel"
              id="gasmakemodel"
              type="text"
              v-model="gasmakemodel"
              placeholder=""
            />
          </div>

          <p class="p-light">
            The proposed Round Robin test methods may be different than your
            laboratories test procedures, including the test levels, sample
            application, and reaction determination. Are you willing to follow
            the applicable ET Users Group Round Robin test procedure?
          </p>

          <div class="flex flex-wrap">
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="willing"
                >
                  Yes
                </label>
                <input
                  name="willing"
                  value="wyes"
                  id="wyes"
                  type="radio"
                  v-model="willing"
                />
              </div>
            </div>
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="willing"
                >
                  No
                </label>
                <input
                  name="willing"
                  value="wno"
                  id="wno"
                  type="radio"
                  v-model="willing"
                />
              </div>
            </div>
          </div>

          <p class="p-light">
            Please record below your comments regarding any concerns with
            following the Round Robin test methods including machine
            verification, sample application, test levels, reaction
            determination, etc.
          </p>

          <div class="mb-4">
            <textarea
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="concerns"
              id="concerns"
              v-model="concerns"
              placeholder=""
            >
            </textarea>
          </div>

          <p class="p-light">
            When do you expect to begin testing according to the Round Robin
            procedure?
          </p>

          <div class="flex flex-wrap">
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="when"
                >
                  Now
                </label>
                <input
                  name="when"
                  value="now"
                  id="now"
                  type="radio"
                  v-model="when"
                />
              </div>
            </div>
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="when"
                >
                  3 Months
                </label>
                <input
                  name="when"
                  value="month3"
                  id="month3"
                  type="radio"
                  v-model="when"
                />
              </div>
            </div>
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="when"
                >
                  6 Months
                </label>
                <input
                  name="when"
                  value="month6"
                  id="month6"
                  type="radio"
                  v-model="when"
                />
              </div>
            </div>
            <div class="w-full md:w-1/4 md:pr-2 mt-4">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="when"
                >
                  1 Year
                </label>
                <input
                  name="when"
                  value="year"
                  id="year"
                  type="radio"
                  v-model="when"
                />
              </div>
            </div>
          </div>

          <p class="p-light">
            Please review the statistical method used to determine if the
            repeatability results and results between laboratories are
            significantly different. The
            <a
              class="link"
              href="https://www.etusersgroup.org/wp-content/uploads/StatisticalRelativeComparisonMethod.pdf"
              target="_blank"
              >Statistical Relative Comparison Method</a
            >
            is a method to statistically compare the results of sensitivity
            tests completed using Bruceton, Neyer, SEQ or other method.
          </p>

          <div class="flex items-center justify-between">
            <button
              id="submit"
              @click="sendRequest"
              style="background-color:#f09a3e;"
              class="mt-8 w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Send
            </button>
          </div>
          <div class="mb-4"></div>
        </div>
      </div>
      <div class="modal">
        <img src="@/assets/ajax-loader.gif" alt="pending" />
        <!-- Place at bottom of page -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoundRobin",
  data: function() {
    return {
      loading: false,
      name: "",
      title: "",
      entity: "",
      location: "",
      email: "",
      phone: "",
      //
      mbom: false,
      ablesd: false,
      ablfriction: false,
      bamfriction: false,
      dsc: false,
      sbat: false,
      koenen: false,
      detonator: false,
      //
      hsv: false,
      godetect: false,
      gasanalyzer: false,
      //
      vidmakemodel: "",
      gasmakemodel: "",
      //
      willing: "",
      //
      concerns: "",
      //
      when: ""
    };
  },
  mounted() {},
  methods: {
    sendRequest() {
      let messageData = {
        name: this.name,
        title: this.title,
        entity: this.entity,
        location: this.location,
        email: this.email,
        phone: this.phone,
        mbom: this.mbom,
        ablesd: this.ablesd,
        ablfriction: this.ablfriction,
        bamfriction: this.bamfriction,
        dsc: this.dsc,
        sbat: this.sbat,
        koenen: this.koenen,
        detonator: this.detonator,
        hsv: this.hsv,
        godetect: this.godetect,
        gasanalyzer: this.gasanalyzer,
        vidmakemodel: this.vidmakemodel,
        gasmakemodel: this.gasmakemodel,
        //
        willing: this.willing,
        //
        concerns: this.concerns,
        //
        when: this.when
      };

      if (!messageData.name) {
        alert("Name is required");
        return false;
      }
      if (!messageData.entity) {
        alert("Company or Entity is required");
        return false;
      }
      if (!messageData.location) {
        alert("Location is required");
        return false;
      }
      if (!messageData.email) {
        alert("Email is required");
        return false;
      }
      if (!messageData.phone) {
        alert("Phone is required");
        return false;
      }

      this.loading = true;
      fetch("/sendRoundRobinMail", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "no-cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(messageData) // body data type must match "Content-Type" header
      }).then(() => {
        this.loading = false;
        alert("Request Sent");
        this.name = "";
        this.title = "";
        this.entity = "";
        this.location = "";
        this.email = "";
        this.phone = "";
        this.mbom = false;
        this.ablesd = false;
        this.ablfriction = false;
        this.bamfriction = false;
        this.dsc = false;
        this.sbat = false;
        this.koenen = false;
        this.detonator = false;
        this.hsv = false;
        this.godetect = false;
        this.gasanalyzer = false;
        this.vidmakemodel = "";
        this.gasmakemodel = "";
        //
        this.willing = "";
        //
        this.concerns = "";
        //
        this.when = "";
      });
    }
  }
};
</script>

<style lang="scss">
.round-robin {
  #submit:hover {
    background-color: #454d5f !important;
  }

  .modal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.8) 50% 50% no-repeat;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 32px;
      height: 32px;
      margin-top: -16px; /* Half the height */
      margin-left: -16px; /* Half the width */
      opacity: 0.8;
    }
  }
  &.loading {
    overflow: hidden;
  }
  &.loading .modal {
    display: block;
  }
}
</style>
